var Loading = {

    loading: false,
    $dialog: null,
    toHide: null,

    show: function()
    {
        if(Loading.toHide != null)
        {
            window.clearTimeout(Loading.toHide);
        }

        if(!Loading.loading)
        {
            var html  = '<div class="modal modal-loading" style="display: none">';
                html += '   <div class="modal-dialog">';
                html += '       <div class="modal-content">';
                html += '           <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>';
                html += '       </div>';
                html += '   </div>';
                html += '</div>';
            Loading.$dialog = $(html);
            Loading.$dialog.appendTo('body');
            Loading.$dialog.modal({show: true, keyboard: false, backdrop: 'static'}).on('hidden.bs.modal', function() {
                $(this).remove();
                Loading.loading = false;
                Loading.$dialog = null;
            });
            Loading.loading = true;
        }
    },

    hide: function()
    {

        if(Loading.loading)
        {
            Loading.toHide = window.setTimeout(function()
            {
                Loading.$dialog.modal('hide')
            }, 200);
        }
    }

}